<template>
  <div class="card" :style="{width,height}">
    <div class="more">
      <div class="icon">
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
          <path
            fill="#E0E0E0"
            fill-rule="nonzero"
            d="M21.058 25.185c-.586.542-1.5.508-2.044-.076l-.062-.072a1.443 1.443 0 0 1 .138-1.97l5.843-5.41H3.73c-.77 0-1.4-.602-1.442-1.362l-.002-.082c0-.797.646-1.443 1.444-1.443h21.203L19.08 9.35l-.069-.07a1.442 1.442 0 0 1 .003-1.961 1.448 1.448 0 0 1 2.043-.076l7.957 7.367a2.186 2.186 0 0 1 0 3.207z"
          />
        </svg>
      </div>
      <span>Посмотреть все</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "ShowMore",
  props: {
    width: {
      type: String,
      default: "300px"
    },
    height: {
      type: String,
      default: "200px"
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/utils/_variables.scss";

.card {
  position: relative;
  overflow: hidden;
  border-radius: 16px;
  background: $color_grey_3;
  transition: all 0.3s ease-in;
  display: flex;
  align-items: center;
  justify-content: center;

  .more {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    font-weight: 500;
    line-height: 1.2;
    letter-spacing: -1px;
    color: rgba(#fff, 0.97);
    .icon {
      background: $color_dark_blue;
      width: 56px;
      height: 56px;
      border-radius: 50%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 24px;
    }
  }
  &:active {
    transform: scale(0.95);
    transition-duration: 0.3s;
  }
}
</style>