<template>
  <div class="tile">
    <div class="image">
      <!-- <img :src="require('@/assets/img/' + this.img)" v-if="img" /> -->
      <img :src="this.img" v-if="img" />
      <span class="title" v-html="title" />
    </div>
  </div>
</template>

<script>
export default {
  name: "TileLittle",
  props: {
    img: {
      type: String,
      default: ""
    },
    title: {
      type: String,
      default: ""
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/utils/_variables.scss";

.tile {
  position: relative;
  &::before {
    content: "";
    position: absolute;
    z-index: 1;
    width: 310px;
    height: 96px;
    top: 328px;
    left: 0;
    -webkit-filter: blur(26px);
    filter: blur(26px);
    background-color: $color_shade;
  }
  .image {
    position: relative;
    z-index: 2;
    width: 310px;
    height: 394px;
    border-radius: 28px;
    overflow: hidden;
    background-color: $color_grey_3;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    &::before {
      content: "";
      position: absolute;
      z-index: 2;
      width: 310px;
      height: 156px;
      bottom: 0;
      left: 0;
      background-blend-mode: multiply;
      background-image: linear-gradient(
        to bottom,
        rgba(36, 36, 35, 0),
        rgba(36, 36, 35, 0.58) 50%
      );
    }
    .title {
      position: absolute;
      z-index: 3;
      bottom: 24px;
      left: 18px;
      width: 310px;
      font-size: 42px;
      font-weight: 500;
      line-height: 1.1;
      letter-spacing: -0.72px;
      color: rgba(#fff, 0.97);
    }
  }
  &:active .image {
    transform: scale(0.92);
    transition-duration: 0.3s;
  }
}
</style>